:root {
    --newColorWhite1: #fff;
    --menuHoverColor2: linear-gradient(to right, #009efd, #2af598);
    --newColorLightBlack: #3f4248;
    /* --newColorAnchorTagBlue:#006d65; */
    --newColorAnchorTagBlue: #3c3744;
    --newColorPlaceholderGrey: #999;
    --newColorBlack: #000;
    --newColorHoverRed: #b33735;
    --newColorHeadingBlack: #141414;
    --imagePath: ../../public/images/;
}

.miisco-logo {
    font-size: 3rem;
    padding: 0, 0, 0, 10px;
    margin: auto;
    color: #fff;
    background: linear-gradient(to left, #009efd, #2af598);
    ;
    border-radius: 5%;
    text-align: center;
    filter: grayscale(100px);
    filter: drop-shadow(8px 0px 8px #2af598);
}

.logo:hover {
    text-decoration: none;
}

nav {
    background: transparent !important;
}

.gradient-line {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, red, orange, yellow, green, blue);
}

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {

    .container-sm,
    .container {
        max-width: 540px
    }
}

@media(min-width: 768px) {

    .container-md,
    .container-sm,
    .container {
        max-width: 720px
    }
}

@media(min-width: 992px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 960px
    }
}

@media(min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px
    }
}

@media(min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--bs-gutter-y));
    margin-right: calc(-.5*var(--bs-gutter-x));
    margin-left: calc(-.5*var(--bs-gutter-x))
}

.row>* {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

@media(min-width: 576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width: 768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width: 1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

@media(min-width: 576px) {
    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }
}

@media(min-width: 768px) {
    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }
}

@media(min-width: 992px) {
    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }
}

@media(min-width: 1200px) {
    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }
}

@media(min-width: 1400px) {
    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
    background: linear-gradient(to right, #009efd, #2af598);
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

* {
    outline: none !important
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important
}

a,
a::after,
.main-navigation,
.sub-menu,
.hamburger,
.has-sub>a:before,
.megamenu-wrapper,
.menu-outer>li>a:before,
.scrolltop-btn,
.header,
.form-control,
.has-sub>a em {
    transition: all .3s;
}

html,
body {
    min-width: 280px
}

body {
    background: linear-gradient(to right, #009efd, #2af598);
    background-size: 100% 460px;
    position: relative;
    margin: 0;
    padding: 50px 0 0 0;
    font-size: 16px;
    color: var(--newColorLightBlack);
    min-height: 100vh;
    overflow-x: hidden;
    font-family: 'Be Vietnam Pro', sans-serif;
    -ms-overflow-style: scrollbar
}

a {
    color: var(--newColorAnchorTagBlue);
    text-decoration: none;
    display: inline-block
}

a:hover {
    text-decoration: underline
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
}

::-webkit-input-placeholder {
    color: var(--newColorPlaceholderGrey)
}

::-moz-placeholder {
    color: var(--newColorPlaceholderGrey);
    opacity: 1
}

:-ms-input-placeholder {
    color: var(--newColorPlaceholderGrey)
}

:-moz-placeholder {
    color: var(--newColorPlaceholderGrey)
}

::-moz-focus-inner {
    border: 0
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--newColorBlack)
}

option:not(:checked) {
    color: var(--newColorBlack)
}

.btn {
    text-decoration: none !important;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
    height: 54px;
    padding: 12px 40px;
    border: 1px solid transparent;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    transition-duration: .3s
}

.btn-outline {
    border-color: var(--newColorHoverRed);
    color: var(--newColorHoverRed)
}

.btn-outline:before {
    background: var(--newColorHoverRed) !important
}

.btn-outline:hover {
    color: var(--newColorWhite1);
    background-color: transparent;
    border-color: var(--newColorHoverRed)
}

.btn-outline:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.btn-outline:focus,
.btn-outline:active {
    color: var(--newColorWhite1);
    background-color: var(--newColorHoverRed);
    border-color: var(--newColorHoverRed)
}

.section-space {
    padding: 80px 0
}

.form-group {
    transition: all .3s;
    margin-bottom: 21px
}

.form-group span {
    display: block;
    padding-bottom: 10px
}

.form-control {
    font-family: 'Be Vietnam Pro', sans-serif;
    background: var(--newColorWhite1);
    border: 1px solid transparent;
    font-size: 18px;
    border-radius: 4px;
    line-height: 1.42857143;
    padding: 10px 15px;
    height: 42px;
    width: 100%
}

.form-control:focus {
    border-color: #9dc6e6 !important
}

textarea.form-control {
    height: auto;
    resize: none
}

.img-fluid {
    max-width: 100%
}

.home-bg {
    /* background: url(../../public/images/main-banner.webp) left top no-repeat; */
    background: var(--menuHoverColor2);
    background-size: 100% 941px
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    background: var(--menuHoverColor2);
    filter: drop-shadow(0px -32px 20px black);
}

.page-scrolled .header {
    background-color: var(--menuHoverColor2);
}

.top-header>.container {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.header>.container {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.logo {
    display: inline-block;
    width: 182px;
    padding: 10px 0
}

.logo>img {
    display: block;
    width: 100%
}

.page-scrolled .header {
    top: 0;
    z-index: 20
}

.page-scrolled .logo {
    width: 160px
}

.main-navigation ul,
.main-navigation li {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1
}

.main-navigation>ul {
    display: flex;
    align-items: center
}

.main-navigation>ul>li>a {
    border: 1px solid transparent;
    text-decoration: none;
    padding: 11px 28px;
    border-radius: 30px;
    position: relative;
    font-size: 17px;
    font-weight: 400;
    color: var(--newColorWhite1);

}

.main-navigation>ul>li.has-sub>a {
    padding-right: 40px
}

.main-navigation>ul>li.has-sub>a em {
    position: absolute;
    padding-right: 50px;
    right: 0;
    top: 1px;
    bottom: 0;
    height: 100%;
    width: 10px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-image: url(../../public/images/down-arrow-menu.webp);
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat
}

.main-navigation>ul>li.has-sub.active>a em {
    transform: rotate(180deg);
}

.main-navigation>ul>li>a:hover,
.main-navigation>ul>li:hover>a,
.main-navigation>ul>li.current_page_item>a {
    background: var(--newColorAnchorTagBlue);
}

.main-navigation>ul>li {
    position: relative;
    transition: all .3s;
    padding-left: 3px
}

.main-navigation>ul>li .sub-menu {
    position: absolute;
    left: 0;
    width: 300px;
    padding: 30px 20px 0 0;
    display: none
}

.main-navigation>ul>li .services{
    /* position: absolute;
    left: -5rem; */
    width: 500px;
    padding: 30px 20px 0 0;
    display: none;
}

.main-navigation>ul>li .sub-menu .service-menu{
    display: flex;
    justify-content: center;
    width: 500px;
    position: relative;
    left: 8rem;
}

.main-navigation>ul>li .sub-menu:after {
    box-shadow: 0 20px 50px rgba(0, 0, 0, .1);
    top: 21px;
    left: -10px;
    border-radius: 8px;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--newColorWhite1);
}

.main-navigation>ul>li:hover .sub-menu {
    display: block;
}

.main-navigation>ul>li .sub-menu li a {
    color: #333;
    text-decoration: none !important;
    padding: 11px 15px;
    font-size: 16px;
    display: block;
    font-weight: 500
}

.main-navigation>ul>li .sub-menu li a:hover {
    background: #f2f7f9;
    color: var(--menuHoverColor2);
    padding: 11px 5px 11px 20px
}

.main-navigation>ul>li.contact-btn {
    margin-left: 15px
}

.main-navigation>ul>li.contact-btn>a {
    border: 1px solid #fff
}

.main-navigation>ul>li.contact-btn>a:hover,
.main-navigation>ul>li.contact-btn:hover>a,
.main-navigation>ul>li.contact-btn.current_page_item>a {
    border-color: var(--newColorAnchorTagBlue)
}

.hamburger {
    position: relative;
    width: 30px;
    margin: 0;
    display: none;
    z-index: 100;
    padding: 24px 0;
    margin-left: auto
}

.hamburger .line {
    width: 30px;
    height: 2px;
    background-color: var(--newColorWhite1);
    display: block;
    margin: 0 auto 6px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.hamburger .line:nth-child(3) {
    margin: 0
}

.hamburger:hover {
    cursor: pointer
}

.hamburger.is-active .line:nth-child(2) {
    opacity: 0
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(10px) rotate(45deg);
    -ms-transform: translateY(10px) rotate(45deg);
    -o-transform: translateY(10px) rotate(45deg);
    transform: translateY(10px) rotate(45deg)
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
    -ms-transform: translateY(-6px) rotate(-45deg);
    -o-transform: translateY(-6px) rotate(-45deg);
    transform: translateY(-6px) rotate(-45deg)
}

.show-menu {
    overflow: hidden !important
}

.show-menu .main-navigation ul {
    flex-direction: column
}

.page-scrolled .main-navigation>ul>li>a::after {
    bottom: 16px
}

.has-sub:before {
    z-index: 13;
    transition: all .3s;
    content: "";
    position: absolute;
    top: 38px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-bottom-color: var(--newColorWhite1);
    width: 30px;
    height: 30px;
    display: none
}

.has-sub:hover:before {
    display: block
}

.main-content {
    padding-top: 90px
}

.home-banner {
    color: var(--newColorWhite1);
    margin-top: -20px;
}

.home-banner h1 {
    font-size: 42px;
    font-weight: 700;
    padding: 130px 0 50px 0;
    margin: 0
}

.home-banner p {
    font-size: 22px;
    font-weight: 300;
    color: #f4f4f4;
    padding: 0 0 50px 0;
    margin: 0;
    width: 92%;
    line-height: 36px
}

.home-banner-img {
    margin-top: 100px
}

.home-banner .btn {
    position: relative;
    background: var(--newColorAnchorTagBlue);
    border-color: var(--newColorAnchorTagBlue);
    color: var(--newColorWhite1);
    height: 60px;
    padding: 15px 50px
}

.home-banner .btn:before {
    transition: all .3s;
    content: "";
    position: absolute;
    top: 20px;
    opacity: 0;
    right: 0;
    width: 22px;
    height: 18px;
    background: url(../../public/images/ban-btn-arrow.webp) left top no-repeat
}

.home-banner .btn:hover {
    padding-right: 65px;
    padding-left: 35px
}

.home-banner .btn:hover:before {
    opacity: 1;
    right: 30px
}

h2 {
    color: var(--newColorHeadingBlack);
    font-size: 36px;
    font-weight: 700;
    margin: 0 0 25px 0
}

p {
    font-size: 17px;
    line-height: 28px;
    margin: 0 0 30px 0
}

.gradient-btn {
    padding: 14px 40px;
    background-image: linear-gradient(to right, #006d65, #325287);
    font-size: 18px;
    color: var(--newColorWhite1);
    position: relative
}

.gradient-btn:before {
    transition: all .3s;
    content: "";
    position: absolute;
    top: 19px;
    opacity: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url(../../public/images/ban-btn-arrow.webp) left top no-repeat;
    background-size: 17px
}

.gradient-btn:hover {
    padding-right: 50px;
    padding-left: 30px
}

.gradient-btn:hover:before {
    opacity: 1;
    right: 25px
}

.home-about {
    margin-top: 40px;
    margin-bottom: 80px;
    padding-top: 50px;
}

.padr-10 {
    padding-right: 10%
}

.card {
    background-color: var(--newColorWhite1);
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(53, 16, 180, .08);
    padding: 20px 30px 30px 30px;
    position: relative;
    transition: all .3s;
    top: 0;
    margin: 0 5px
}

.card:after {
    content: '';
    position: absolute;
    width: 86%;
    height: 100%;
    bottom: -15px;
    background: var(--newColorWhite1);
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    box-shadow: 0 0 20px 0 rgba(53, 16, 180, .08);
    border-radius: 5px
}

.card:hover {
    top: -4px;
    transition: all .3s
}

.card:hover::after {
    bottom: 0;
    transition: all .3s;
    box-shadow: none
}

.home-about .card {
    margin-bottom: 40px;
    height: calc(100% - 40px)
}

.home-about .card h4 {
    color: #ee6b4d;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto 10px;
    background: url(../../public/images/about-card-text-bg.png) center no-repeat;
    padding: 15px;
    transition: all .3s
}

.home-about .card p,
.home-services .card p {
    color: var(--newColorHeadingBlack);
    font-size: 22px;
    margin: 0;
    text-align: center;
    font-weight: 500;
    transition: all .3s
}

.home-about .btn {
    margin: 5px 0 40px 0
}

.light-bg {
    background-color: #f8faff
}

.why-choose {
    padding: 80px 0 100px 0;
    position: relative;
    background: #f8faff url(../../public/images/home-why-us-bg.webp) left bottom no-repeat
}

.why-choose img {
    max-width: 100%;
    margin-left: -80px
}

.inr-listing {
    margin: 0;
    padding: 0
}

.inr-listing li {
    color: var(--newColorHeadingBlack);
    font-size: 18px;
    margin: 0 0 20px 0;
    font-weight: 500;
    background: url(../../public/images/circle-check.webp) 0 1px no-repeat;
    padding: 0 0 0 32px
}

.why-choose a {
    margin-top: 25px
}

.home-services {
    text-align: center;
    background: url(../../public/images/services-bg.webp) center bottom no-repeat;
    padding: 100px 0;
    background-size: 100%
}

.home-services .card {
    display: block;
    text-decoration: none;
    margin: 0 5% 40px 5%;
    height: calc(100% - 40px);
    padding: 10px 20px 25px 20px
}

.home-services .card span {
    display: inline-block;
    width: 130px;
    height: 130px;
    background: url(../../public/images/home-sr-icon.webp) 0 0 no-repeat
}

.home-services .card span.sr2 {
    background-position: 0 -130px
}

.home-services .card span.sr3 {
    background-position: 0 -260px
}

.home-services .card span.sr4 {
    background-position: 0 -390px
}

.home-services .card span.sr5 {
    background-position: 0 -520px
}

.home-services .card span.sr6 {
    background-position: 0 -650px
}

.home-services .card span.sr7 {
    background-position: 0 -780px
}

.home-services .card span.sr8 {
    background-position: 0 -910px
}

.home-services .card span.sr9 {
    background-position: 0 -1040px
}

.home-services .card span.sr10 {
    background-position: 0 -1170px
}

.home-services h2 {
    padding-bottom: 25px
}

.capcha {
    margin: 25px 0
}

.home-contact {
    /* background: var(--menuHoverColor2) url(../../public/images/contact-bg.webp) 25% top no-repeat; */
    padding: 80px 0
}

.home-contact input {
    max-height: 40px;
    font-size: 16px;
    margin-bottom: 25px
}

.home-contact input::placeholder,
.home-contact textarea::placeholder {
    color: #917fbf
}

.home-contact h2,
.home-contact p {
    color: var(--newColorWhite1)
}

.btn-blue {
    padding: 14px 40px;
    background: var(--newColorAnchorTagBlue);
    font-size: 18px;
    color: var(--newColorWhite1);
    position: relative
}

.btn-blue:before {
    transition: all .3s;
    content: "";
    position: absolute;
    top: 19px;
    opacity: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url(../../public/images/ban-btn-arrow.webp) left top no-repeat;
    background-size: 17px
}

.btn-blue:hover {
    padding-right: 50px;
    padding-left: 30px
}

.btn-blue:hover:before {
    opacity: 1;
    right: 25px
}

.feedback {
    padding: 80px 0 40px 0;
    background: linear-gradient(to right, #009efd, #2af598);
    background-size: contain;
    border-top: 1px solid rgba(255, 255, 255, .178);
}

.feedback .custom-card {
    background-color: var(--newColorWhite1);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(53, 16, 180, .1);
    max-width: 490px;
    margin-bottom: 30px
}

.feedback .custom-card .card-body {
    display: flex;
    align-items: flex-start
}

.feedback .custom-card .card-body .img-div {
    background-color: #f1f1f1;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    min-width: 70px
}

.feedback .custom-card .card-body .img-div img {
    width: 100%;
    border-radius: 50%
}

.feedback .custom-card .card-body .content h4 {
    color: var(--newColorHeadingBlack);
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: bold
}

.feedback .custom-card .card-body .content p {
    margin: 0
}

.feedback .custom-card .card-body .content {
    text-align: left;
    padding-left: 25px
}

.feedback .margin-left {
    margin-left: 50px
}

.blog-sec {
    padding: 20px 0 70px 0;
    background: url(../../public/images/content-bg.webp) center top no-repeat
}

.blog-sec h2 {
    text-align: center;
    padding-bottom: 30px
}

.blog-card {
    box-shadow: 0 0 20px 0 rgba(53, 16, 180, .1);
    border-radius: 8px;
    background-color: var(--newColorWhite1);
    overflow: hidden;
    margin: 0 15px 30px 15px
}

.blog-card .blog-img {
    position: relative
}

.blog-card .blog-img img {
    width: 100%;
    height: auto
}

.blog-card .blog-date {
    position: absolute;
    bottom: -20px;
    right: 20px;
    background-color: var(--newColorWhite1);
    box-shadow: 0 0 10px 1px #3510b421;
    padding: 8px 20px;
    border-radius: 5px
}

.blog-card .blog-date p {
    color: #3534b2;
    font-size: 16px;
    margin: 0
}

.blog-card .content {
    padding: 25px
}

.blog-card .content label {
    color: var(--newColorPlaceholderGrey);
    font-size: 16px
}

.blog-card .content h4 {
    color: var(--newColorHeadingBlack);
    font-size: 20px;
    margin: 10px 0
}

.blog-card .content h4 a {
    color: var(--menuHoverColor2);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
    margin: 0 0 20px
}

.blog-card .content p {
    margin: 20px 0 15px
}

.blog-card .gradient-btn {
    padding: 12px 15px;
    height: 50px;
    display: block;
    width: fit-content;
    margin-left: auto
}

.blog-card .gradient-btn img {
    max-width: 18px;
    position: relative;
    transition: all .3s;
    right: 0
}

.blog-card .gradient-btn:hover img {
    right: -5px
}

.blog-card .gradient-btn::before {
    content: '';
    display: none
}

.footer {
    color: var(--newColorWhite1);
    background-color: var(--menuHoverColor2);
    padding: 40px 0 0 0
}

.footer p.address {
    font-size: 16px;
    margin-top: 5px;
}

.footer h4 {
    font-size: 18px;
    font-weight: 600;
    margin: -5px 0 25px 0
}

.footer h3 {
    color: var(--newColorWhite1);
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0 30px
}

.social-icons {
    padding: 0;
    margin: 0
}

.social-icons li {
    padding: 0 7px 0 0;
    margin: 0;
    display: inline-block
}

.social-icons li a {
    border-radius: 40px;
    opacity: .95;
    border-radius: 20px;
    display: block;
    width: 40px;
    height: 40px;
    background: url(../../public/images/ic-social-media.svg) 0 0 no-repeat;
    background-size: 140px 80px
}

.social-icons li a:hover {
    opacity: 1;
    background-position-y: -40px !important
}

.social-icons li a.twitter {
    background-position: -50px 0
}

.social-icons li a.linkedin {
    background-position: -100px 0
}

.footer ul.footlink {
    padding: 0;
    margin: 0
}

.footer ul.footlink li a {
    text-decoration: none;
    color: var(--newColorWhite1);
    font-size: 16px;
    font-weight: normal;
    padding-left: 25px;
    margin-bottom: 23px;
    background-image: url(../../public/images/ban-btn-arrow.webp);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: left center
}

.footer ul.footlink li a:hover {
    color: var(--newColorAnchorTagBlue);
    padding-left: 30px
}

.footer .row ul {
    margin: 0
}

.footer .copyright {
    border-top: 1px solid rgba(255, 255, 255, .178);
    padding: 25px 0;
    margin-top: 30px;
    color: rgba(255, 255, 255, .7)
}

.footer .copyright p {
    font-size: 15px;
    margin-bottom: 0
}

.footer .copyright a {
    font-size: 15px;
    color: rgba(255, 255, 255, .7)
}

.footer .copyright a:hover {
    text-decoration: underline;
    color: var(--newColorAnchorTagBlue)
}

.inner-banner {
    color: var(--newColorWhite1);
    min-height: 320px;
    display: flex;
    align-items: center
}

.inner-banner h1 {
    font-size: 46px;
    font-weight: 700;
    margin: 0
}

.inner-banner p {
    font-size: 16px;
    color: #efefef;
    margin: 15px 0 25px 0
}

.sr-top-section {
    padding: 80px 0 60px 0
}

.sr-top-section .sr-top-section-img {
    margin-left: -120px;
    position: relative;
    z-index: 1
}

.sr-top-section .sr-top-section-img:before {
    content: "";
    position: absolute;
    width: 90%;
    height: 90%;
    background-color: #fec0b2;
    top: -10px;
    left: -10px;
    z-index: -1;
    border-radius: 10px
}

.sr-top-section.light-bg .sr-top-section-img {
    margin-left: 25px
}

.sr-top-section.light-bg .sr-top-section-img:before {
    background-color: #b7daf0
}

.qa-client {
    padding: 80px 0
}

.qa-client img {
    max-width: 100%
}

.b-top-pad {
    padding-top: 40px
}

.why-we {
    background: url(../../public/images/content-bg.webp) center no-repeat;
    padding: 80px 0 50px
}

.why-we .card {
    margin: 0 10px 50px 10px;
    padding: 20px 25px 1px 25px;
    height: calc(100% - 50px)
}

.why-we h2 {
    text-align: center;
    margin-bottom: 50px
}

.why-we .card h4 {
    color: var(--newColorHeadingBlack);
    font-size: 24px;
    font-weight: 500;
    margin: 3px 0 13px 0
}

.why-we .card span {
    display: inline-block;
    width: 90px;
    height: 90px;
    background: url(../../public/images/service-sr-icon.webp) 0 0 no-repeat
}

.why-we .card span.sr2 {
    background-position: 0 -90px
}

.why-we .card span.sr3 {
    background-position: 0 -180px
}

.why-we .card span.sr4 {
    background-position: 0 -270px
}

.why-we .card span.sr5 {
    background-position: 0 -360px
}

.why-we .card span.sr6 {
    background-position: 0 -450px
}

.why-we .card span.sr7 {
    background-position: 0 -540px
}

.why-we .card span.sr8 {
    background-position: 0 -630px
}

.why-we .card span.sr9 {
    background-position: 0 -720px
}

.why-we .card span.sr10 {
    background-position: 0 -810px
}

.why-we .card span.sr11 {
    background-position: 0 -900px
}

.why-we .card span.sr12 {
    background-position: 0 -990px
}

.why-we .card span.sr13 {
    background-position: 0 -1080px
}

.why-we .card span.sr14 {
    background-position: 0 -1170px
}

.why-we .card span.sr15 {
    background-position: 0 -1260px
}

.why-we .card span.sr16 {
    background-position: 0 -1350px
}

.why-we .card span.sr17 {
    background-position: 0 -1440px
}

.why-we .card span.sr18 {
    background-position: 0 -1530px
}

.why-we .card span.sr19 {
    background-position: 0 -1620px
}

.why-we .card span.sr20 {
    background-position: 0 -1710px
}

.why-we .card span.sr21 {
    background-position: 0 -1800px
}

.why-we .card span.sr22 {
    background-position: 0 -1890px
}

.why-we .card span.sr23 {
    background-position: 0 -1980px
}

.why-we .card span.sr24 {
    background-position: 0 -2070px
}

.why-we .card span.sr25 {
    background-position: 0 -2160px
}

.why-we .card span.sr26 {
    background-position: 0 -2250px
}

.why-we .card span.sr27 {
    background-position: 0 -2340px
}

.why-we .card span.sr28 {
    background-position: 0 -2430px
}

.why-we .card span.sr29 {
    background-position: 0 -2520px
}

.why-we .card span.sr30 {
    background-position: 0 -2610px
}

.why-we .card span.sr31 {
    background-position: 0 -2700px
}

.why-we .card span.sr32 {
    background-position: 0 -2790px
}

.why-we .card span.sr33 {
    background-position: 0 -2880px
}

.why-we .card span.sr34 {
    background-position: 0 -2970px
}

.why-we .card span.sr35 {
    background-position: 0 -3060px
}

.why-we .card span.sr36 {
    background-position: 0 -3150px
}

.why-we .card span.sr37 {
    background-position: 0 -3240px
}

.why-we .card span.sr38 {
    background-position: 0 -3330px
}

.why-we .card span.sr39 {
    background-position: 0 -3420px
}

.why-we .card span.sr40 {
    background-position: 0 -3510px
}

.why-we .card span.sr41 {
    background-position: 0 -3600px
}

.why-we .card span.sr42 {
    background-position: 0 -3690px
}

.why-we .card span.sr43 {
    background-position: 0 -3780px
}

.why-we .card span.sr44 {
    background-position: 0 -3870px
}

.why-we .card span.sr45 {
    background-position: 0 -3960px
}

.why-we .card span.sr46 {
    background-position: 0 -4050px
}

.why-we .card span.sr47 {
    background-position: 0 -4140px
}

.software-testing {
    padding: 80px 0
}

.software-testing h2 {
    text-align: center;
    margin-bottom: 28px
}

.software-testing p {
    text-align: center;
    max-width: 1125px;
    margin: 0 auto 60px
}

.software-testing .round-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: 10px auto;
    box-shadow: 0 0 30px 0 rgba(53, 16, 180, .2);
    padding: 30px 30px 30px 30px;
    position: relative;
    transition: all .3s;
    background-color: var(--newColorWhite1)
}

.software-testing .round-card:after {
    content: "";
    position: absolute;
    bottom: 24px;
    right: -23px;
    width: 52px;
    height: 30px;
    border-bottom: solid 30px var(--newColorWhite1);
    border-left: solid 25px transparent;
    border-right: solid 30px transparent;
    transform: rotate(122deg)
}

.software-testing h5 {
    font-size: 18px;
    line-height: 26px;
    color: var(--newColorHeadingBlack);
    max-width: 182px;
    text-align: center;
    margin: 13px auto 13px
}

.software-testing .round-card span {
    display: inline-block;
    width: 90px;
    height: 90px;
    background: url(../../public/images/service-testing-process-sr-icon.webp) 0 0 no-repeat
}

.software-testing .round-card .sr2 {
    background-position: 0 -90px
}

.software-testing .round-card .sr3 {
    background-position: 0 -180px
}

.software-testing .round-card .sr4 {
    background-position: 0 -270px
}

.software-testing .round-card .sr5 {
    background-position: 0 -360px
}

.software-testing .round-card .sr6 {
    background-position: 0 -450px
}

.software-testing .flex-sm-column-reverse .round-card::after {
    transform: rotate(62deg);
    bottom: 115px;
    right: -23px
}

.software-testing .round-card.last:after {
    display: none
}

@media(max-width: 1600px) {
    .home-banner-img img {
        width: 100%
    }
}

@media(max-width: 1500px) {
    .sr-top-section .sr-top-section-img {
        margin-left: 0;
        margin-right: 0
    }

    .sr-top-section img {
        width: 95%;
        height: auto
    }
}

@media(max-width: 1399px) {
    .home-bg {
        background-size: 100% 800px
    }

    .home-banner h1 {
        font-size: 42px;
        padding: 90px 0 35px 0
    }

    .home-banner p {
        width: 100%;
        font-size: 21px
    }

    .home-about .card h4 {
        font-size: 48px
    }

    .home-about .card p,
    .home-services .card p {
        font-size: 20px;
        line-height: 26px
    }

    .card {
        padding: 20px 30px 25px 30px
    }

    .home-services {
        padding: 80px 0
    }

    .home-services .card {
        padding: 3px 12px 23px 12px;
        margin: 0 3% 40px 3%
    }

    .home-services .card p {
        margin-top: -10px
    }

    .padr-10 {
        padding-right: 6%
    }

    .why-choose img {
        max-width: 90%;
        margin-left: 0
    }

    .blog-card {
        margin: 0 5px 30px 5px
    }

    .qa-client {
        padding: 60px 0
    }

    .b-top-pad {
        padding-top: 30px
    }

    .why-we .card {
        margin: 0 5px 50px 5px
    }

    .why-we .card h4 {
        font-size: 22px
    }

    .why-we {
        padding: 60px 0 30px
    }

    .why-we h2 {
        margin-bottom: 36px
    }

    .software-testing p {
        margin: 0 auto 30px
    }

    .software-testing .round-card {
        margin: 0 auto
    }

    .software-testing {
        padding: 70px 0 30px 0
    }

    .software-testing h5 {
        margin: 13px auto 30px
    }
}

@media(max-width: 1199px) {
    .home-bg {
        background-size: 100% 650px
    }

    .home-banner h1 {
        font-size: 42px;
        padding: 40px 0 25px 0
    }

    .home-banner p {
        font-size: 20px;
        padding: 0 0 35px 0;
        line-height: 32px
    }

    .logo {
        width: 150px;
        padding: 7px 0
    }

    .page-scrolled .logo {
        width: 135px
    }

    .main-navigation>ul>li>a {
        padding: 11px 20px
    }

    .main-navigation>ul>li .sub-menu:after {
        top: 13px
    }

    .main-navigation>ul>li {
        padding-left: 1px
    }

    .main-navigation>ul>li.contact-btn {
        margin-left: 10px
    }

    .padr-10 {
        padding-right: calc(var(--bs-gutter-x)*.5)
    }

    h2 {
        font-size: 32px
    }

    .home-services {
        padding: 60px 0 40px 0
    }

    .home-services h2 {
        padding-bottom: 10px
    }

    .home-services .card {
        padding: 5px 10px 20px 10px;
        margin: 0 0 40px 0
    }

    .home-services .card p {
        margin-top: -7px;
        font-size: 18px;
        line-height: 24px
    }

    .home-services .card span {
        width: 104px;
        height: 104px;
        background-size: 104px
    }

    .home-services .card span.sr2 {
        background-position: 0 -104px
    }

    .home-services .card span.sr3 {
        background-position: 0 -208px
    }

    .home-services .card span.sr4 {
        background-position: 0 -312px
    }

    .home-services .card span.sr5 {
        background-position: 0 -416px
    }

    .home-services .card span.sr6 {
        background-position: 0 -520px
    }

    .home-services .card span.sr7 {
        background-position: 0 -624px
    }

    .home-services .card span.sr8 {
        background-position: 0 -728px
    }

    .home-services .card span.sr9 {
        background-position: 0 -832px
    }

    .home-services .card span.sr10 {
        background-position: 0 -936px
    }

    .home-contact {
        padding: 60px 0
    }

    .home-contact .col-lg-6.col-xl-5 {
        padding-right: 35px
    }

    .feedback {
        padding: 50px 0
    }

    .blog-sec {
        padding: 0 0 40px 0
    }

    .blog-sec h2 {
        padding-bottom: 15px
    }

    .blog-card .content h4 {
        font-size: 18px;
        margin: 10px 0
    }

    .blog-card .content p {
        margin: 15px 0 10px
    }

    .blog-card {
        margin: 0 0 30px 0
    }

    body {
        background-size: 100% 400px
    }

    .inner-banner {
        min-height: 266px
    }

    .inner-banner h1 {
        font-size: 42px
    }

    .sr-top-section .sr-top-section-img {
        margin-left: 20px;
        margin-bottom: 20px
    }

    .sr-top-section {
        padding: 60px 0 30px 0
    }

    .qa-client {
        padding: 40px 0 30px 0;
        text-align: center
    }

    .why-we .card {
        margin: 0 0 30px 0;
        height: calc(100% - 30px)
    }

    .why-we .card h4 {
        font-size: 20px
    }

    .why-we .card {
        padding: 10px 25px 1px 25px
    }

    .why-we {
        padding: 50px 0 30px
    }

    .why-we h2 {
        margin-bottom: 30px
    }

    .software-testing h2 {
        margin-bottom: 15px
    }

    .software-testing {
        padding: 50px 0 10px 0
    }

    .software-testing h5 {
        margin: 13px auto 30px
    }

    .why-choose {
        padding: 60px 0 70px 0
    }
}

@media(max-width: 1024px) {

    html,
    body {
        overflow-x: hidden
    }
}

@media(max-width: 991px) {
    body {
        font-size: 15px;
        padding-top: 66px
    }

    .main-content {
        padding-top: 66px
    }

    .hamburger {
        display: block;
        top: 0
    }

    .main-navigation>ul>li {
        padding-left: 0;
        padding-bottom: 5px
    }

    .main-navigation>ul>li>a {
        font-size: 16px;
        white-space: nowrap;
        font-weight: 500
    }

    .sticky-page-nav.show-menu .hamburger,
    .show-menu .page-scrolled .hamburger {
        top: -7px
    }

    .sticky-page-nav .page-header .page-navigation>li>a::after {
        bottom: 1px
    }

    .logo {
        /* width: 135px;q */
        padding: 7px 0
    }

    .page-scrolled .logo {
        width: 135px
    }

    .main-navigation {
        position: fixed;
        display: block;
        top: 66px;
        left: 100%;
        width: 100%;
        height: 100vh;
        background-color: var(--menuHoverColor2);
        z-index: 99;
        bottom: 0;
        overflow: hidden
    }

    .main-navigation>ul>li .sub-menu,
    .main-navigation>ul>li .megamenu-wrapper {
        position: static;
        width: 100%;
        padding: 0;
        display: none;
        box-shadow: none
    }

    .main-navigation>ul>li:hover .sub-menu,
    .main-navigation>ul>li:hover .megamenu-wrapper {
        display: none
    }

    .show-menu .main-navigation {
        left: 0
    }

    .main-navigation>ul {
        padding: 20px 25px;
        display: block;
        text-align: left;
        margin-top: 0;
        overflow: auto;
        max-height: calc(100vh - 66px);
        overflow-x: hidden
    }

    .main-navigation>ul>li+li {
        margin-left: 0
    }

    .main-navigation>ul>li>a {
        padding: 15px 30px !important;
        display: block
    }

    .main-navigation>ul>li>a::after {
        display: none
    }

    .main-navigation>ul>li.has-sub>a em {
        display: inline-flex
    }

    .main-navigation>ul>li.contact-btn {
        margin-left: 0
    }

    .main-navigation>ul>li.contact-btn>a {
        border-color: transparent
    }

    .main-navigation>ul>li .sub-menu {
        background: transparent
    }

    .is-sub {
        transition: none !important
    }

    .main-navigation>ul>li .sub-menu:after {
        display: none
    }

    .has-sub:before,
    .has-sub:hover:before {
        display: none
    }

    .main-navigation>ul>li .sub-menu li a {
        color: var(--newColorWhite1);
        padding-left: 50px
    }

    .main-navigation>ul>li .sub-menu li a:hover {
        color: var(--newColorWhite1);
        background: transparent;
        padding-left: 60px
    }

    .home-banner {
        text-align: center
    }

    .home-banner h1 {
        font-size: 32px;
        padding: 10px 0 20px 0;
        color: var(--newColorHeadingBlack)
    }

    .home-banner p {
        color: var(--newColorLightBlack);
        font-size: 18px;
        padding: 0 0 28px 0;
        line-height: 30px
    }

    .home-banner .btn {
        height: 50px;
        padding: 13px 40px;
        font-size: 18px
    }

    .home-banner .btn:hover {
        padding-right: 50px;
        padding-left: 30px
    }

    .home-banner-img {
        margin-top: 60px
    }

    .home-banner .btn:before {
        background-size: 20px;
        top: 17px
    }

    .home-banner .btn:hover:before {
        right: 22px
    }

    .home-bg {
        background: url(../../public/images/main-banner-mobile.webp) center -50px no-repeat;
        background-size: 100%
    }

    .home-banner-img img {
        max-width: 400px;
        height: auto
    }

    .home-banner-img {
        margin-top: 30px
    }

    .feedback .custom-card {
        margin: 20px auto
    }

    h2 {
        font-size: 26px;
        margin: 0 0 20px 0
    }

    .home-about {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 50px;
    }

    p {
        line-height: 26px;
        margin: 0 0 20px 0
    }

    .gradient-btn,
    .btn-blue {
        padding: 13px 35px;
        font-size: 16px;
        height: 48px
    }

    .gradient-btn:before,
    .btn-blue:before {
        top: 17px
    }

    .gradient-btn:hover,
    .btn-blue:hover {
        padding-right: 45px;
        padding-left: 25px
    }

    .gradient-btn:hover:before,
    .btn-blue:hover:before {
        right: 20px
    }

    .home-about .card h4 {
        font-size: 40px;
        margin: 0 auto
    }

    .home-about .card p,
    .home-services .card p {
        font-size: 18px;
        line-height: 22px
    }

    .why-choose {
        padding: 50px 0 60px 0;
        background-size: 200%
    }

    .why-choose img {
        display: none
    }

    .inr-listing li {
        font-size: 17px;
        margin-bottom: 16px
    }

    .why-choose a {
        margin-top: 10px
    }

    .home-services .card p br {
        display: none
    }

    .home-services {
        padding: 40px 0 25px 0
    }

    .home-contact .col-lg-6.col-xl-5 {
        padding-right: calc(var(--bs-gutter-x)*.5)
    }

    .home-contact {
        padding: 40px 0
    }

    .feedback {
        padding: 40px 0 25px 0;
        text-align: center
    }

    .feedback .col-lg-6.col-xl-5 {
        margin-bottom: 20px
    }

    .foot-logo {
        width: 120px
    }

    body {
        background-size: 100% 350px
    }

    .inner-banner {
        min-height: 246px
    }

    .inner-banner h1 {
        font-size: 34px
    }

    .inner-banner p {
        margin: 10px 0 25px 0
    }

    .blog-card .blog-img {
        text-align: center;
        background-color: var(--menuHoverColor2)
    }

    .blog-card .blog-img img {
        width: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto
    }

    .blog-card {
        margin: 0 0 25px 0
    }

    .why-we .card {
        padding: 5px 25px 1px 25px
    }

    .why-we .card h4 {
        margin: 0 0 7px 0
    }

    .software-testing {
        padding: 40px 0 10px 0
    }

    .software-testing h5 {
        margin: 13px auto 20px
    }
}

@media(max-width: 767px) {
    .container {
        padding-right: 20px;
        padding-left: 20px
    }

    .main-navigation>ul {
        padding: 20px 10px
    }

    .home-banner-img img {
        width: 60%
    }

    .home-banner-img {
        margin-top: 10px
    }

    .home-banner h1 {
        font-size: 26px;
        padding: 0 0 10px 0
    }

    .home-banner p {
        font-size: 16px;
        padding: 0 0 20px 0;
        line-height: 26px
    }

    h2 {
        font-size: 20px;
        margin: 0 0 15px 0
    }

    .home-about {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px
    }

    p {
        line-height: 24px;
        margin: 0 0 20px 0;
        font-size: 15px
    }

    .home-services {
        padding: 30px 0 10px 0
    }

    .home-contact {
        padding: 30px 0
    }

    .blog-sec h2 {
        padding-bottom: 5px
    }

    .blog-card .content h4 {
        font-size: 17px
    }

    .blog-card .content p {
        margin: 10px 0 10px
    }

    .blog-sec {
        padding: 0 0 15px 0
    }

    .copyright .justify-content-between {
        justify-content: center !important
    }

    .copyright .col-auto {
        padding-top: 10px
    }

    .footer .copyright {
        padding: 10px 0 20px 0;
        margin-top: 10px
    }

    .footer {
        padding: 20px 0 0 0
    }

    .footer h3 {
        font-size: 22px;
        margin: 20px 0 15px
    }

    body {
        background-size: 300% 265px
    }

    .inner-banner {
        min-height: 170px
    }

    .inner-banner h1 {
        font-size: 24px
    }

    .inner-banner p {
        margin: 10px 0 10px 0
    }

    .sr-top-section {
        padding: 45px 0 20px 0
    }

    .sr-top-section .sr-top-section-img:before {
        top: -5px;
        left: -5px
    }

    .inr-listing li {
        line-height: 21px;
        font-size: 16px;
        margin-bottom: 12px
    }

    .qa-client {
        padding: 25px 0 25px 0;
        text-align: center
    }

    .why-we {
        padding: 40px 0 20px
    }

    .why-we h2 {
        margin-bottom: 25px
    }

    .software-testing h2 {
        margin-bottom: 13px
    }

    .software-testing {
        padding: 30px 0 15px 0
    }

    .software-testing h5 {
        margin: 13px auto 20px;
        font-size: 17px;
        line-height: 23px
    }

    .software-testing .round-card {
        width: 130px;
        height: 130px;
        padding: 20px 20px 20px 20px
    }

    .feedback .custom-card .card-body .content h4 {
        font-size: 18px
    }

    .why-we .card span {
        width: 70px;
        height: 70px;
        background-size: 70px 3290px
    }

    .why-we .card span.sr2 {
        background-position: 0 -70px
    }

    .why-we .card span.sr3 {
        background-position: 0 -140px
    }

    .why-we .card span.sr4 {
        background-position: 0 -210px
    }

    .why-we .card span.sr5 {
        background-position: 0 -280px
    }

    .why-we .card span.sr6 {
        background-position: 0 -350px
    }

    .why-we .card span.sr7 {
        background-position: 0 -420px
    }

    .why-we .card span.sr8 {
        background-position: 0 -490px
    }

    .why-we .card span.sr9 {
        background-position: 0 -560px
    }

    .why-we .card span.sr10 {
        background-position: 0 -630px
    }

    .why-we .card span.sr11 {
        background-position: 0 -700px
    }

    .why-we .card span.sr12 {
        background-position: 0 -770px
    }

    .why-we .card span.sr13 {
        background-position: 0 -840px
    }

    .why-we .card span.sr14 {
        background-position: 0 -910px
    }

    .why-we .card span.sr15 {
        background-position: 0 -980px
    }

    .why-we .card span.sr16 {
        background-position: 0 -1050px
    }

    .why-we .card span.sr17 {
        background-position: 0 -1120px
    }

    .why-we .card span.sr18 {
        background-position: 0 -1190px
    }

    .why-we .card span.sr19 {
        background-position: 0 -1260px
    }

    .why-we .card span.sr20 {
        background-position: 0 -1330px
    }

    .why-we .card span.sr21 {
        background-position: 0 -1400px
    }

    .why-we .card span.sr22 {
        background-position: 0 -1470px
    }

    .why-we .card span.sr23 {
        background-position: 0 -1540px
    }

    .why-we .card span.sr24 {
        background-position: 0 -1610px
    }

    .why-we .card span.sr25 {
        background-position: 0 -1680px
    }

    .why-we .card span.sr26 {
        background-position: 0 -1750px
    }

    .why-we .card span.sr27 {
        background-position: 0 -1820px
    }

    .why-we .card span.sr28 {
        background-position: 0 -1890px
    }

    .why-we .card span.sr29 {
        background-position: 0 -1960px
    }

    .why-we .card span.sr30 {
        background-position: 0 -2030px
    }

    .why-we .card span.sr31 {
        background-position: 0 -2100px
    }

    .why-we .card span.sr32 {
        background-position: 0 -2170px
    }

    .why-we .card span.sr33 {
        background-position: 0 -2240px
    }

    .why-we .card span.sr34 {
        background-position: 0 -2310px
    }

    .why-we .card span.sr35 {
        background-position: 0 -2380px
    }

    .why-we .card span.sr36 {
        background-position: 0 -2450px
    }

    .why-we .card span.sr37 {
        background-position: 0 -2520px
    }

    .why-we .card span.sr38 {
        background-position: 0 -2590px
    }

    .why-we .card span.sr39 {
        background-position: 0 -2660px
    }

    .why-we .card span.sr40 {
        background-position: 0 -2730px
    }

    .why-we .card span.sr41 {
        background-position: 0 -2800px
    }

    .why-we .card span.sr42 {
        background-position: 0 -2870px
    }

    .why-we .card span.sr43 {
        background-position: 0 -2940px
    }

    .why-we .card span.sr44 {
        background-position: 0 -3010px
    }

    .why-we .card span.sr45 {
        background-position: 0 -3080px
    }

    .why-we .card span.sr46 {
        background-position: 0 -3150px
    }

    .why-we .card span.sr47 {
        background-position: 0 -3220px
    }
}

@media(max-width: 575px) {
    .home-bg {
        background: url(../../public/images/main-banner-mobile.webp) center top no-repeat;
        background-size: 100%
    }

    .blog-card .blog-img img {
        width: 100%
    }

    .why-we {
        padding: 30px 0 10px
    }

    .why-we h2 {
        margin-bottom: 20px
    }
}

@media(max-width: 475px) {
    .feedback .custom-card .card-body {
        display: block
    }

    .feedback .custom-card .card-body .img-div {
        margin: 0 auto 15px
    }

    .feedback .custom-card .card-body .content {
        padding-left: 0;
        text-align: center
    }
}

.inner-about {
    margin: 40px 0 0 0 !important
}

.vision-mission-box {
    padding-top: 40px
}

.bg-light-red {
    background-color: #fff8f7
}

.bg-light-blue {
    background-color: #f2f8ff
}

.vision-mission-box .bg-light-red,
.vision-mission-box .bg-light-blue {
    padding: 30px;
    margin-bottom: 30px;
    height: calc(100% - 30px);
    border-radius: 8px
}

.vision-mission-box h3 {
    font-size: 26px;
    margin: 0 0 13px 0
}

.vision-mission-box p {
    margin: 0
}

.title-text {
    font-size: 19px;
    line-height: 32px;
    text-align: center;
    max-width: 1125px;
    margin: -20px auto 60px
}

.inner-contact {
    padding: 80px 0 0 0
}

.inner-contact .form-control {
    border: 1px solid #e7e6ea;
    font-size: 16px;
    margin-bottom: 25px
}

.inner-contact input::placeholder,
.inner-contact textarea::placeholder {
    color: #917fbf
}

.inner-contact .capcha {
    margin-top: 0
}

.about-why {
    background: url(../../public/images/content-bg.webp) center no-repeat;
    padding: 80px 0 50px
}

.about-why .card {
    margin: 0 10px 50px 10px;
    padding: 20px 25px 1px 25px;
    height: calc(100% - 50px)
}

.about-why h2 {
    text-align: center;
    margin-bottom: 50px
}

.about-why .card h4 {
    color: var(--newColorHeadingBlack);
    font-size: 24px;
    font-weight: 500;
    margin: 3px 0 13px 0
}

.about-why .card span {
    display: inline-block;
    width: 80px;
    height: 90px;
    background: url(../../public/images/about-why-icon.webp) 0 0 no-repeat
}

.about-why .card span.sr2 {
    background-position: 0 -90px
}

.about-why .card span.sr3 {
    background-position: 0 -180px
}

.about-why .card span.sr4 {
    background-position: 0 -270px
}

.about-why .card span.sr5 {
    background-position: 0 -360px
}

.about-why .card span.sr6 {
    background-position: 0 -450px
}

.inner-feedback h2 {
    text-align: center
}

.inner-feedback .custom-card {
    max-width: 100%;
    height: calc(100% - 30px)
}

.inner-feedback .title-text {
    margin: -5px auto 45px
}

.contact-us-block {
    padding: 80px 0 80px 0;
    background: url(../../public/images/content-bg.webp) center top no-repeat
}

.contact-us-block h2 {
    text-align: center
}

.contact-us-block .card {
    padding: 70px 10%
}

.contact-us-block .card:hover {
    top: 0 !important
}

.contact-us-block .card:hover:after {
    bottom: -15px !important;
    box-shadow: 0 0 20px 0 rgba(53, 16, 180, .08) !important
}

.contact-us-block .title-text {
    margin: -10px auto 50px
}

.contact-us-block .form-control {
    border: 1px solid #e7e6ea;
    font-size: 16px;
    margin-bottom: 25px
}

.contact-us-block input::placeholder,
.contact-us-block textarea::placeholder {
    color: #917fbf
}

.contact-us-block .capcha {
    margin-top: 0
}

.contact-us-block h4 {
    margin: 80px 0 20px 0;
    font-size: 28px;
    color: var(--newColorHeadingBlack);
    text-align: center
}

a.callus,
a.mailus {
    display: inline-block;
    padding: 0 15px 15px 15px;
    position: relative;
    font-size: 20px;
    color: var(--newColorLightBlack);
    text-decoration: none;
    font-weight: 500
}

a.callus:hover,
a.mailus:hover {
    color: var(--menuHoverColor2)
}

.contact-text {
    font-size: 20px;
    text-align: center
}

a.callus img,
a.mailus img {
    vertical-align: -20px;
    margin-right: 5px
}

.inner-block {
    padding: 60px 0 50px 0
}

.inner-block .title-text {
    text-align: left !important;
    max-width: 100%;
    margin: 0 0 20px 0;
    padding: 0
}

.inner-block strong {
    font-weight: 600;
    color: var(--newColorHeadingBlack);
}

.inner-block h5 {
    font-size: 24px;
    color: var(--newColorHeadingBlack);
    font-weight: 600;
    margin: 0 0 20px 0;
    padding: 10px 0 0 0
}

.inner-block .inr-listing li {
    line-height: 28px;
    background: url(../../public/images/circle-check.webp) 0 2px no-repeat;
    color: var(--newColorLightBlack);
    font-weight: 400
}

.inner-block .inr-listing {
    padding-left: 15px
}

@media(max-width: 1399px) {
    .about-why .card {
        margin: 0 5px 50px 5px
    }

    .about-why .card h4 {
        font-size: 22px
    }

    .about-why {
        padding: 60px 0 30px
    }

    .about-why h2 {
        margin-bottom: 36px
    }

    .contact-us-block {
        padding: 60px 0 60px 0
    }

    .contact-us-block .title-text {
        margin: -5px auto 40px
    }

    .contact-us-block .card {
        padding: 60px 7%
    }
}

@media(max-width: 1199px) {
    .inner-contact {
        padding: 60px 0
    }

    .inner-contact .col-lg-6.col-xl-5 {
        padding-right: 35px
    }

    .about-why .card {
        margin: 0 0 30px 0;
        height: calc(100% - 30px)
    }

    .about-why .card h4 {
        font-size: 20px
    }

    .about-why .card {
        padding: 10px 25px 1px 25px
    }

    .about-why {
        padding: 35px 0 30px
    }

    .about-why h2 {
        margin-bottom: 30px
    }

    .vision-mission-box {
        padding-top: 15px
    }

    .vision-mission-box .bg-light-red,
    .vision-mission-box .bg-light-blue {
        padding: 25px;
        margin-bottom: 25px;
        height: calc(100% - 25px)
    }

    .vision-mission-box h3 {
        font-size: 24px;
        margin: 0 0 10px 0
    }

    .title-text {
        font-size: 17px;
        line-height: 28px;
        margin: -15px auto 35px
    }

    .inner-feedback .title-text {
        margin: -5px auto 35px
    }

    .contact-us-block h4 {
        margin: 60px 0 20px 0
    }

    .inner-block {
        padding: 50px 0 40px 0
    }
}

@media(max-width: 991px) {
    .inner-contact .col-lg-6.col-xl-5 {
        padding-right: calc(var(--bs-gutter-x)*.5)
    }

    .inner-contact {
        padding: 40px 0
    }

    .about-why .card {
        padding: 5px 25px 1px 25px
    }

    .about-why .card h4 {
        margin: 0 0 7px 0
    }

    .vision-mission-box .bg-light-red,
    .vision-mission-box .bg-light-blue {
        padding: 20px;
        margin-bottom: 25px;
        height: calc(100% - 25px)
    }

    .vision-mission-box h3 {
        font-size: 22px
    }

    .inner-feedback .title-text {
        margin: -5px auto 20px
    }

    .inner-feedback .custom-card {
        margin: 15px auto
    }

    .contact-us-block {
        padding: 40px 0 40px 0
    }

    .contact-us-block .title-text {
        margin: -5px auto 30px
    }

    .contact-us-block .card {
        padding: 50px 7%
    }

    .contact-us-block h4 {
        font-size: 24px;
        margin: 50px 0 15px 0
    }

    .contact-text {
        font-size: 18px;
        margin: 0 0 15px 0
    }

    a.callus,
    a.mailus {
        padding: 0 15px 5px 15px;
        font-size: 18px
    }
}

@media(max-width: 767px) {
    .inner-contact {
        padding: 30px 0
    }

    .inner-about {
        margin: 25px 0 0 0 !important
    }

    .about-why {
        padding: 15px 0 20px
    }

    .about-why h2 {
        margin-bottom: 25px
    }

    .title-text {
        font-size: 16px;
        line-height: 28px;
        margin: -15px auto 25px
    }

    .contact-us-block {
        padding: 25px 0 30px 0
    }

    .contact-us-block .card {
        padding: 30px 7%
    }

    .contact-us-block h4 {
        font-size: 22px;
        margin: 40px 0 15px 0
    }

    .inner-block {
        padding: 25px 0 20px 0
    }

    .inner-block h5 {
        font-size: 20px;
        margin: 0 0 20px 0;
        padding: 0
    }

    .inner-block .inr-listing li {
        line-height: 25px
    }

    .software-testing .round-card span {
        width: 64px;
        height: 64px;
        background-size: 100%
    }

    .software-testing .round-card .sr2 {
        background-position: 0 -64px
    }

    .software-testing .round-card .sr3 {
        background-position: 0 -128px
    }

    .software-testing .round-card .sr4 {
        background-position: 0 -192px
    }

    .software-testing .round-card .sr5 {
        background-position: 0 -256px
    }

    .software-testing .round-card .sr6 {
        background-position: 0 -320px
    }
}

@media(max-width: 575px) {
    .home-bg {
        background: url(../../public/images/main-banner-mobile.webp) center top no-repeat;
        background-size: 100%
    }

    .blog-card .blog-img img {
        width: 100%
    }

    .about-why {
        padding: 30px 0 10px
    }

    .about-why h2 {
        margin-bottom: 20px
    }
}

.site-map-section {
    padding: 60px 0
}

.sitemap-list,
.sitemap-list ul {
    margin: 0;
    padding: 0
}

.sitemap-list li {
    margin: 0;
    padding: 0
}

.sitemap-list li li {
    display: inline-block
}

.sitemap-list a {
    display: inline-block;
    padding: 10px 30px;
    margin: 0 10px 15px 0;
    border-radius: 30px;
    background-color: var(--newColorWhite1);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2)
}

.sitemap-list a:hover {
    color: var(--newColorWhite1);
    background-color: var(--menuHoverColor2);
    text-decoration: none
}

.sitemap-list strong {
    display: block;
    font-size: 21px;
    padding: 15px 0 13px 0
}

.page-not-found {
    padding-top: 60px;
    margin-bottom: 25px
}

.page-not-found h2 {
    text-align: center
}

.page-not-found .title-text {
    margin: 0 auto 60px
}

.footer h4 a {
    color: var(--newColorWhite1)
}