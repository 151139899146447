/* @media (max-width: 992px) and (min-width: 768px) {
    .top-bar-section {
        position: fixed;
        top: 0;
        width: 100%;
        background: #fff !important;
        z-index: 9;
    }

    .header_section {
        top: 46px;
    }

    body #rmp_menu_trigger-2478 {
        top: 61px;
    }

    .partner-map h2 {
        font-size: 120px;
    }

    .partner-map h4 {
        font-size: 26px;
    }

    .partner-items ul li {
        padding: 26px 15px;
    }

    .partner-items ul img {
        max-height: 80px;
    }

    .partner-items ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .partner-items ul li {
        position: relative;
        padding: 30px;
        display: flex;
        align-items: center;
    }

    .partner-items ul li::after {
        position: absolute;
        right: -1px;
        top: 0;
        content: "";
        height: 100%;
        width: 1px;
        background: #e7e7e7;
    }

    .partner-items ul img {
        width: auto;
        text-align: center;
        margin: auto;
        max-height: 100px;
    }

    .partner-items ul li {
        padding: 16px 40px;
        justify-content: center;
    }

    .partner-items ul {
        grid-template-columns: 1fr 1fr;
    }

    .partner-items ul img {
        max-height: 60px;
    }

    .partner-items ul img {
        margin: 0 0;
    }

    @media (min-width: 992px) {
        .col-lg-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }

        .client-requirement-content .mr-4 {
            margin-right: 30px;
        }

        .partner-items {
            padding-left: 70px;
        }
    } */

/* manual css properties */

.partner-items {
    display: grid;

    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1f;
    justify-content: center;
    gap: 100px;
    height: 100%;
    list-style-type: none;
    margin-left: -10px;
    overflow: normal;

}

.partner-items div {
    /* font-size: 100px; */
    /* border-right: 1px solid black; */
    /* box-shadow: 0px 0px 2px 0px black; */
    /* max-width: 100%; */
    /* overflow: hidden; */
    /* width: 10%; */
    /* text-align: center; */
}

.partner-items div img {
    /* width: 100%; */
    /* max-width: 100%; */
}

.parter-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* text-align: center; */
    gap: 50px;
    height: 100%;
}

.parter-grid h2 {
    font-size: 100px;
}

.parter-grid p {
    margin-top: -25px;
}

.parter-grid div img {
    width: 100%;
    margin-top: 40px;
}


.testimonial-read-more-button {
    border-radius: 6px;
    border: none;
    font-size: 1rem;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
}

.testimonial-down-collapse-btn {
    position: relative;
    left: 23rem;
    width: 8%;
    transform: rotate(180deg);
    cursor: pointer;
}


.danger-link-not-working {
    position: relative;
    left: 200px;
    display: inline-block;
    background-color: red;
    border: 1px solid black;
    border-radius: 10px;
    padding: 3px;
    font-size: 8px;
    color: yellow;
    top: -34px;
}

.react-links {
    cursor: pointer !important;
}