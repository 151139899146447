body {
    zoom: 0.97;
    transform-origin: 0 0;
    /* Set the origin to the top-left corner */
}

@media (max-width: 900px) {
    .profile-card-warpper {
        display: grid;
        grid-template-columns: 1fr !important;
        justify-items: center !important;
        text-align: center;
        gap: 10px;
    }

    .profile-card {
        border: 1px solid #ddd;
        /* border-radius: 8px; */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin: 10px;
        padding: 20px;
        width: 340px;
        background-color: #ddd;
        margin-left: 0px !important;

        /* box-shadow: 1px 0px 30px -20px black; */
        /* box-shadow: -1px 10px 30px 0px black; */
        border-radius: 30px;
        box-shadow: -1px 1px 28px -10px black;
    }
}

.profile-card-warpper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    text-align: center;
    gap: 10px;
}

.profile-card {
    border: 1px solid #ddd;
    /* border-radius: 8px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 340px;
    background-color: #ddd;
    margin-left: -70px;

    /* box-shadow: 1px 0px 30px -20px black; */
    /* box-shadow: -1px 10px 30px 0px black; */
    border-radius: 30px;
    box-shadow: -1px 1px 28px -10px black;
}

.profile-image {
    width: 50%;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.profile-position {
    color: #555;
    margin-bottom: 10px;
}

.profile-description {
    color: #777;
}