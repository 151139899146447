.product-page {
    padding: 50px 0;
  }
  
  .banner {
    background: 
      url('../../public/images/banner.jpg'), 
      linear-gradient(to right, #007bff, #00aaff);
    background-size: cover;
    padding: 50px 20px;
    color: white;
    text-align: center;
  }
  
  .banner h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    margin: 10px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .product-heading {
    font-size: 3rem;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.7);
  }

  .product-details {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
   
  }
  
  .product-image {
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    margin: 20px;
  }
  
  .product-description {
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color:#cfc1c1;
    margin-left: 10px;
    padding: 15px;
  }
  
  .product-features-container {
    margin-top: 30px;
    width: 100%;
  }
  
  .product-features {
    list-style-type: none;
    padding: 0;
    gap: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .product-features li {
    margin:10px 20px 10px 20px;
    background: #f0f0f0;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .download-btn {
    padding: 12px 25px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .download-btn:hover {
    background-color: #0056b3;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .banner h1 {
      font-size: 3rem;
    }

  
    .product-details {
      flex-direction: column;
      padding: 20px;
      text-align: center;
    }
  
    .product-image {
      max-width: 100%;
      height: auto;
    }
  
    .product-description {
      font-size: 1rem;
    }
  
    .product-features {
      flex-direction: column;
      align-items: center;
    }
  
    .download-btn {
      padding: 10px 20px;
      font-size: 1rem;
    }
    .product-features li {
        margin:5px;
      }
  }
  


  /* For Extra Small Devices */
  @media (max-width: 480px) {
    .banner h1 {
      font-size: 2rem;
    }
  
    .product-heading {
      font-size: 1.4rem;
    }
  
    .product-description {
      font-size: 0.9rem;
    }
  
    .download-btn {
      padding: 8px 18px;
      font-size: 0.9rem;
    }
  }
  