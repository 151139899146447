.project-card {
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.project-card:hover {
    transform: scale(1.04);
}

/* Styles for project titles and descriptions */
.project-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.project-description {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Styles for project images (you can use image URLs here) */
.project-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;

    box-shadow: 1px 1px 3px black;

}

.top-shadow {
    box-shadow: 1px 0px 30px -20px black;
    /* box-shadow: -1px 10px 30px 0px black; */
    border-radius: 30px 30px 0 0;
    margin-top: 16px;
}

.home-card-shadow {
    box-shadow: 1px 1px 20px -10px black;
}

.hidden {
    visibility: hidden;
}

/* Style for a disabled link */
.disabled-link {
    color: #999;
    /* Change the text color to a muted gray (you can adjust the color as needed) */
    pointer-events: none;
    /* Disable pointer events, making the link unclickable */
    text-decoration: none;
    /* Optionally remove underline (if present) */
}

.address {
    margin-bottom: 8px !important;
}

/* Develoment Menu Css */


.sub-menu h4 {
    font-weight: 800;
    font-size: 16px;
    color: rgb(79, 90, 91);
}


.main-navigation>ul>li .sub-menu-develop:after {
    box-shadow: 0 20px 50px rgba(0, 0, 0, .1);
    top: 21px;
    left: -100px;
    border-radius: 8px;
    position: absolute;
    content: "";
    width: 200%;
    height: 100%;
    background: var(--newColorWhite1);
}

.main-navigation>ul>li .sub-menu-develop {
    position: absolute;
    left: 0;
    width: 300px;
    padding: 30px 20px 0 0;
    display: none
}

.main-navigation>ul>li:hover .sub-menu-develop {
    display: block
}

.main-navigation>ul>li .sub-menu-develop li a {
    color: #333;
    text-decoration: none !important;
    padding: 11px 15px;
    font-size: 16px;
    display: block;
    font-weight: 500
}

.main-navigation>ul>li .sub-menu-develop li a:hover {
    background: #f2f7f9;
    color: var(--menuHoverColor2);
    padding: 11px 5px 11px 20px;
}

.service-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 200%;
    margin-left: -30%;
}

.left-menu {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
}
.left-menu h4{
    margin-right: 1rem;
}

.right-menu {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.right-menu li h4{
    margin-left: 1rem;
}

.service-menu h4 {
    text-align: center;
    text-decoration: underline;
    margin-left: -60px;
}

.healthcare-icon {
    background: url(../../public/images/healthcare.png);
    height: 30%;
    width: auto;
    max-width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15px;
}

.logistics-icon {
    background: url(../../public/images/delivery-truck.png);
    height: 30%;
    width: auto;
    max-width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15px;
}

.ecommerce-icon {
    background: url(../../public/images/ecommerce.png);
    height: 30%;
    width: auto;
    max-width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15px;
}