@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styles for mobile navigation */
.mobile-navigation {
  position: relative;
}

.hamburger-menu {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  /* background: white; */
  background: linear-gradient(to right, #009efd, #2af598);
}

.main-menu li {
  border-bottom: 1px solid #ccc;
  padding: 20px 0 0 0;
}

/* .main-menu a, .main-menu button {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: #333;
} */


.main-menu a,
.main-menu button {
  text-decoration: none;
  color: var(--newColorWhite1);
  font-size: 16px;
  font-weight: normal;
  padding-left: 25px;
  margin-bottom: 23px;
  background-image: url(../public/images/ban-btn-arrow.webp);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: left center
}

.main-menu a:hover,
.main-menu button:hover {
  color: var(--newColorAnchorTagBlue);
  padding-left: 30px
}

.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #f9f9f9;
}

.submenu li {
  border-bottom: 1px solid #ddd;
}

.menu-item-with-submenu {
  position: relative;
  padding-top: 10px !important;
}

.menu-item-with-submenu .submenu {
  display: none;
}

.menu-item-with-submenu .submenu.show {
  display: block;
}

/* Show menu on mobile */
@media (max-width: 768px) {
  .main-menu {
    display: block;
  }
}